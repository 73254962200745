import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Función que intenta cargar la imagen y retorna el formato correcto
const getValidImageUri = (base64Data) => {
  if (base64Data.startsWith("/9j/")) {
    // Si la cadena Base64 empieza con /9j/, es un JPEG
    return `data:image/jpeg;base64,${base64Data}`;
  } else {
    // Si no, asumimos que es PNG
    return `data:image/png;base64,${base64Data}`;
  }
};


const styles = StyleSheet.create({
  page: {
    justifyContent: "center",
    backgroundColor: "#fcfcfc",
    borderWidth: 0.5,
    padding:20
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    paddingVertical: 20,
    color: "grey",
  },
  text: {
    marginVertical: 8,
    fontSize: 14,
  },
  sub: {
    color: "orange",
    fontSize: 18,
    textAlign: "center",
    marginBottom: 40,
    textDecoration: "underline",
  },
  tableContainer: {
    margin: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    padding: 10,
  },
  listItem: {
    padding: 10,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
    backgroundColor: "#f9f9f9",
  },
  itemIndex: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 8,
    color:"orange"
  },
  listText: {
    fontSize: 14,
    marginBottom: 6,
  },
  boldText: {
    fontWeight: "bold",
  },
  photoContainer: {
    flexDirection: "row",

    justifyContent: "space-between", // Espacio entre la imagen y el texto
    marginVertical: 10, // Espacio entre filas
  },
  img: {
    width: 200,
    height: 200, // Mantén las dimensiones proporcionales
    resizeMode: "contain", // Evita que las imágenes se deformen
    borderWidth: 1,
    borderColor: "#ccc", // Añade un borde para mejor visualización
    borderRadius: 10, // Bordes redondeados para un estilo más suave
  },
  imgDoc:{
    width: 280,
    height: 220, // Mantén las dimensiones proporcionales
    resizeMode: "contain", // Evita que las imágenes se deformen
    borderWidth: 1,
    borderColor: "#ccc", // Añade un borde para mejor visualización
    borderRadius: 10, 
  }
});

const MyDocument = ({data}) => (

  <Document>
    <Page size="A4" style={styles.page}>
          <Text style={styles.title}>Legajo usuario</Text>
      <View style={styles.section}>
        <View style={styles.listItem}>
          <Text style={styles.sub}>Datos Personales</Text>
       
          <Text style={styles.text}>
            Nombre Completo: {data?.users?.name} {data.users?.lastName}
          </Text>
          <Text style={styles.text}>CUIT: {data.users?.cuit}</Text>
          <Text style={styles.text}>
            Fecha de nacimiento: {data.users?.birthDate}
          </Text>
          <Text style={styles.text}>
            Número de tel.: {data.users?.phoneNumber}
          </Text>
          <Text style={styles.text}>
           Cvu: {data.users?.cvu}
          </Text>
          <Text style={styles.text}>
           Alias: {data.users?.label}
          </Text>
          <Text style={styles.text}>Correo: {data.users?.email}</Text>
          <Text style={styles.text}>Provincia: {data.users?.province}</Text>
          <Text style={styles.text}>Ciudad: {data.users?.city}</Text>
          <Text style={styles.text}>Dirección: {data.users?.address}</Text>
         <Text style={styles.text}>Código postal:{data.users?.postalCode} </Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.photoContainer}>
          <Text>Selfie</Text>
          <Image
            source={{
              uri: getValidImageUri(data.foto?.["selfie"]),
            }}
            style={styles.img}
          />
        </View>

        <View style={styles.photoContainer}>
          <Text>Dni Frente</Text>
          <Image
            source={{
              uri: getValidImageUri(data.foto?.["dni-front"]),
            }}
            style={styles.imgDoc}
          />
        </View>

        <View style={styles.photoContainer}>
          <Text>Dni Dorso</Text>
          <Image
            source={{
              uri: getValidImageUri(data.foto?.["dni-back"]),
            }}
            style={styles.imgDoc}
          />
        </View>
      </View>
<View style={styles.section}>
<Text style={styles.sub}>Movimientos</Text>

{data.mov?.map((el, i) => (
  <View style={styles.listItem} key={i}>
    <Text style={styles.itemIndex}>Código: {el.metadata?.origin_id}</Text>
    {
     el.metadata?.nOperation &&(
      <Text style={styles.itemIndex}>N° Operación {el.metadata?.nOperation}</Text>
     )
    }
    <Text style={styles.listText}>
      <Text style={styles.boldText}>Fecha de operación:</Text>{" "}
      {el.timestamp?.replace("T", " ").replace("Z", "")}
    </Text>
    <Text style={styles.listText}>
      <Text style={styles.boldText}>CUIT de destino:</Text>{" "}
      {el.movimiento?.cuitDest}
    </Text>

    <Text style={styles.listText}>
      <Text style={styles.boldText}>Estado:</Text>{" "}
      {el.movimiento?.status}
    </Text>

    <Text style={styles.listText}>
      <Text style={styles.boldText}>Monto:</Text> ${" "}
      {el.movimiento?.balance} {el.movimiento?.currency}
    </Text>

    <Text style={styles.listText}>
      <Text style={styles.boldText}>CVU destino:</Text>{" "}
      {el.movimiento?.cvuDest}
    </Text>

    <Text style={styles.listText}>
      <Text style={styles.boldText}>Concepto:</Text>{" "}
      {el.metadata?.concept}
    </Text>

    <Text style={styles.listText}>
      <Text style={styles.boldText}>Método de Pago:</Text>{" "}
      {el.metadata?.wayToPay}
    </Text>
  </View>
))}
</View>
</Page>

</Document>

);

export default MyDocument;