import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { adminUpdate, getAdmin } from "../../redux/actions";
import styles from "../../styles/Users.module.css";
import Paginado from "../Consola usuarios/Paginado";
import { Spiner } from "../Spiner";
import Search_ope from "./Search_ope";
import { Link } from "react-router-dom";
import { IoMdPersonAdd } from "react-icons/io";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { BiSearch } from "react-icons/bi";
import { IoReload } from "react-icons/io5";

const Administradores = () => {
  const dispatch = useDispatch();
  const administradores = useSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [verPorPagina, setVerPorPagina] = useState(10);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  useEffect(() => {
  //console.log("Administradores antes de fetch:", administradores);
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getAdmin());
      setLoading(false);
    };

    fetchData();

    setTimeout(() => {
      if (!administradores?.length) {
        setShowNoDataMessage(true);
      }
    }, 2000);
  }, [dispatch]);

  const updateState = async (_id, newState) => {
    try {
      const data = { _id, state: newState };
      const response = await dispatch(adminUpdate(data));
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: `Se ${newState ? "habilitó" : "inhabilitó"} a este usuario`,
        });
        await dispatch(getAdmin()); // Actualizar la lista de administradores después de la actualización
      }
    } catch (error) {
      console.error("Error updating administrator state:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el estado del administrador",
      });
    }
  };

  const reload = () => {
    setLoading(true);
    dispatch(getAdmin());
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const maximo = Math.ceil(administradores?.length / verPorPagina);

  return (
    <div className={`container`}>
      <div className="d-flex align-items-center p-1">
        <IoReload
          title="Recargar"
          onClick={reload}
          style={{
            fontSize: "25px",
            color: "orange",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
        <h5 style={{ margin: "10px", marginLeft: "10px" }}>
          Admin. Operadores Consola
        </h5>
        <Link
          to={"/register"}
          style={{ textDecoration: "none", color: "black", marginLeft: "50%" }}
        >
          <IoMdPersonAdd style={{ fontSize: "20px", color: "orange" }} />
          Registrar operador
        </Link>
      </div>

      <Search_ope />

      {administradores && administradores.length > 0 ? (
        <div id="#admin">
          <div className="row">
            <div className="d-flex">
              <p className="fs-6">
                Total de Operadores: {administradores?.length}
              </p>
            </div>
          </div>
          <table className={`table`}>
            <thead className="table-info">
              <tr>
                <th>Usuario</th>
                <th>Nombre y Apellido</th>
                <th>Rol</th>
                <th>Correo</th>
                <th>DNI</th>
                <th>Estado del usuario</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {administradores
                .slice(
                  (pagina - 1) * verPorPagina,
                  (pagina - 1) * verPorPagina + verPorPagina
                )
                .map((adm) => (
                  <tr key={adm?._id}>
                    <td className="text-start">{adm?.user}</td>
                    <td>{adm?.name}</td>
                    <td>{adm.role}</td>
                    <td>{adm?.email}</td>
                    <td>{adm?.dni}</td>
                    <td className="d-flex">
                      <div>
                        <Switch
                          onChange={(checked) => updateState(adm._id, checked)}
                          checked={adm.state}
                        />
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          marginLeft: 30,
                        }}
                      >
                        {adm.state ? "Activo" : "Inactivo"}{" "}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Paginado data={maximo} pagina={pagina} setPagina={setPagina} />
        </div>
      ) : (
        showNoDataMessage && (
          <div className="alert alert-warning" role="alert">
            No se encontraron datos. <BiSearch style={{ fontSize: "30px" }} />
          </div>
        )
      )}
    </div>
  );
};

export default Administradores;
