import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../styles/Users.module.css";
import { BiSearch } from "react-icons/bi";
import { IoReload } from "react-icons/io5";
import Search from "./Search";
import Paginado from "./Paginado";
import { getAsociados } from "../../redux/actions";
import { Spiner } from "../Spiner";
import Modal from "react-modal";
import DetalleAsociado from "../Consola usuarios/DetalleAsociado";
import Movimientos from "./Movimientos";
import { GrTransaction } from "react-icons/gr";
import { CgMoreO } from "react-icons/cg";
const Asociados = () => {
  const usuarios = useSelector((state) => state.asociados);
  const totalPage = useSelector((state) => state.totalAsc);
  const [selectedIdMov, setSelectedIdMov] = useState(null);
  const [modalMovIsOpen, setIsMovOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams({
    message: "Este es un mensaje de que estoy en asociados",
  }).toString();

  // Fetch asociados data
  const fetchData = useCallback(async () => {
     setLoading(true);
    try {
      await dispatch(getAsociados(page));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Show "No Data" message
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNoDataMessage(usuarios?.length === 0);
    }, 2000);

    return () => clearTimeout(timer);
  }, [usuarios]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Modal functions
  const openModal = (id) => {
    setSelectedId(id);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedId(null);
  };

  //*----modal movmientos usuario-----
  const openModalMov = (cuit) => {
    setSelectedIdMov(cuit);
    setIsMovOpen(true);
  };

  const closeModalMov = () => {
    setIsMovOpen(false);
    setSelectedIdMov(null);
  };
  return (
    <div className="container">
      <div className="d-flex align-items-center p-1">
        <IoReload
          title="Recargar"
          onClick={() => fetchData(1)}
          style={{
            fontSize: "25px",
            color: "orange",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
        <h5
          style={{ margin: "10px", marginLeft: "30px" }}
          className="text-center"
        >
          Admin. de Asociados
        </h5>
      </div>

      {loading ? (
        <Spiner />
      ) : (
        <>
          <Search setPagina={setPage} Asociados="yes" />
          {usuarios && usuarios.length > 0 ? (
            <div>
              <table className={`table`}>
                <thead className="table-info">
                  <tr>
                    <th>Fecha de registro</th>
                    <th>Nombre y Apellido</th>
                    <th>DNI</th>
                    <th>Tutor</th>
                    <th>Cuil del Tutor</th>
                    <th>Movimientos</th>
                    <th>Ver detalle</th>
                  </tr>
                </thead>
                <tbody>
                  {usuarios.map((user, index) => (
                    <tr key={index}>
                      <td>{new Date(user.dateCreated)?.toLocaleString()}</td>
                      <td>{`${user?.name} ${user?.lastName}`}</td>
                      <td>{user?.dni}</td>
                      <td>
                        {user.tutorDetails?.[0]?.name}{" "}
                        {user.tutorDetails?.[0]?.lastName}
                      </td>
                      <td>{user.tutorDetails?.[0]?.cuit}</td>
                      <td>
                        <button
                          className="btn p-1"
                          onClick={() => openModalMov(user?.cuit)}
                        >
                          <GrTransaction size={22} color="salmon" />
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn p-1"
                          onClick={() => openModal(user?._id)}
                        >
                          <CgMoreO size={22} />
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                  <Modal
                    isOpen={modalMovIsOpen}
                    className={styles.modal}
                    overlayClassName={styles.Overlay} //fondo atras
                    onRequestClose={closeModalMov}
                    contentLabel="Movimientos"
                  >
                    <Movimientos
                      cuit={selectedIdMov}
                      type=""
                      page={page}
                      setPagina={setPage}
                      closeModalMov={closeModalMov}
                      message="asociado mov"
                    />
                  </Modal>
                  <Modal
                    isOpen={modalIsOpen}
                    className={styles.modal}
                    overlayClassName={styles.Overlay}
                    onRequestClose={closeModal}
                    contentLabel="Detalle Asociado"
                  >
                    <DetalleAsociado
                      id={selectedId}
                      pagina={page}
                      setPagina={setPage}
                      closeModal={closeModal}
                      message="ventana asociado"
                    />
                  </Modal>
                </tbody>
              </table>

              <Paginado
                data={totalPage}
                pagina={page}
                setPagina={handlePageChange}
              />
            </div>
          ) : (
            showNoDataMessage && (
              <div className="alert alert-warning" role="alert">
                No se encontraron datos.{" "}
                <BiSearch style={{ fontSize: "30px" }} />
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default Asociados;
