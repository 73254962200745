import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransacciones, transaccion_detalle } from "../../redux/actions";
import { useEffect, useState } from "react";
import Search_trans from "./Search_trans";
import { Spiner } from "../Spiner";
import s from "../../styles/Transacciones.module.css";
import Paginado from "../Consola usuarios/Paginado";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import Detalle_Tranferencia from "./Detalle_Tranferencia";
import { IoReload } from "react-icons/io5";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Modal from "react-modal";
import ConciliacionDetalle from "./ConciliacionDetalle";
import {
  conciliar_transfer,
  clearConcilicionDetail,
} from "../../redux/actions";

const ListaTransacciones = () => {
  const transacciones = useSelector((state) => state.transacciones);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [codigo, setCodigo] = useState("");
const [message,setMessage]= useState(false)
  // Paginado
  const [pagina, setPagina] = useState(1);
  const [verPorPagina, setVerPorPagina] = useState(13);
  const maximo = Math.ceil(transacciones?.length / verPorPagina);
  const [reload, setReload] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [textBtn, setTextBtn] = useState("Buscar");

  const body = {
    obp_status: "",
    obp_from_date: "",
    obp_to_date: "",
    obp_limit: 200,
    obp_offset: 0,
    obp_origin: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      //console.log(transacciones)
      try {
        // setLoading(true);
        // await dispatch(getTransacciones(body));
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const timer = setTimeout(() => {
      if (!transacciones?.length) {
        setShowNoDataMessage(true);
        setLoading(false); // Stop loading if no data is found
      }
    }, 10000);

    return () => clearTimeout(timer); // Clear timer if component unmounts
  }, [dispatch]);

  if (loading) {
    return <Spiner />;
  }

  const clearTrans = async () => {
    await dispatch(clearConcilicionDetail());
  };

  function closeModal() {
    setIsOpen(false);
    setSelectedId(null);
  }

  const recargarTrans = async () => {
    await dispatch(getTransacciones(body));
    setReload(true);
  };

  const conciliar = async (e) => {
    e.preventDefault();
    // console.log(id)
    // setSelectedId(id);
    setTextBtn("Buscando...")
    setIsOpen(false);

    try {
      const res = await dispatch(conciliar_transfer({ id: codigo }));
      if (res?.status === 200) {
        setIsOpen(true);
        setMessage(false)
      }else{
        setIsOpen(false);
      setMessage(true)
      }
      //console.log(res)
    } catch (error) {
      console.log(error);
    }finally{
      setTextBtn("Buscar")
    }
  };

  return (
    <div className="container">
      <div className="d-flex ">
        {/* <IoReload
          title="Recargar"
          onClick={recargarTrans}
          style={{
            fontSize: "25px",
            color: "orange",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        /> */}
        <h5 className="text-center ms-4">Conciliación</h5>
      </div>
      <div className="row">
      <div className="col-lg-4 col-md-6 mb-2">
        <label htmlFor="" className={""}>
          Codigo de transacción:
        </label>
        <input
          className="form-control"
          type="text"
          value={codigo}
          name="codigo"
          onChange={(e) => setCodigo(e.target.value)}
        />
      </div>
      <div className="col-lg-3 mt-4">
        <button
          onClick={(e) => conciliar(e)}
          disabled={textBtn === "Buscando.."}
          style={{
            border: "solid 1px var(--naranja)",
            fontSize: "14px",
            marginLeft: "10px",
          }}
          className="btn btn-outline btn-lg"
          type="submit"
        >
          {textBtn}
        </button>
      </div>

      </div>
      {
        message && (
      <div class="alert alert-info" role="alert">
          No se encontraron datos
       </div>

        )
      }
      <Modal
        isOpen={modalIsOpen}
        className={s.modal}
        onRequestClose={closeModal}
        overlayClassName={s.Overlay} //fondo atras
        contentLabel="Example Modal"
      >
        <ConciliacionDetalle closeModal={closeModal} id={selectedId} />
      </Modal>

    </div>
  );
};

export default ListaTransacciones;
