import React from 'react'
import { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Spiner } from '../Spiner';
const ShopDetail = (params) => {
    const shop= useSelector(state=>state.shop_detalle)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
 
    }, [])
    const getTaxpayerType = (type) => {
        switch (type) {
          case 0.02066:
            return "Monotributista";
          default:
            return "Responsable Inscripto";
        }
      };
    return (
        <div className="container ">
          <div className="d-flex justify-content-between mb-3">
            <Link onClick={params.closeModaldetail}>
              <IoArrowBackCircleOutline
                style={{ fontSize: "30px", color: "orange" }}
              />
            </Link>
           
          </div>
          {!loading ? (
            <div className="card p-3">
              <h5
                className="mb-5 text-center p-2"
                style={{ color: "white", backgroundColor: "var(--naranja" }}
              >
                {" "}
                Detalle del Comercio
              </h5>
              <form className="row g-3">
             
                <div className="col-md-4">
                  <label htmlFor="inputEmail4" className="Form-label">
                   Nombre del comercio
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    name="businessName"
                    value={shop?.nameShop}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputPassword4" className="Form-label">
                    CUIT
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    value={shop?.cuit}
                    name="cuit"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputAddress2" className="Form-label">
                    Correo Electrónico
                  </label>
                  <input
                    disabled
                    value={shop?.email}
                    name="email"
                    type="text"
                    className="form-control"
                    id="inputAddress2"
                    placeholder=""
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputPassword4" className="Form-label">
                    CVU
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    value={shop?.cvu ?shop.cvu : "Pendiente"}
                    name="cuit"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputPassword4" className="Form-label">
                    Alias
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    value={shop?.label? shop.label : "Pendiente"}
                    name="cuit"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputPassword4" className="Form-label">
                    Perfil de riesgo
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    value={shop?.riskProfile}
                    name="cuit"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                 Balance $
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={parseFloat(shop?.currentBalance ?.toFixed(2))?.toLocaleString('es-ES', { minimumFractionDigits: 2 }) + " " + shop?.currency}
                    name="name"
                    disabled
                  />
                </div>
             
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                 Tipo
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={ getTaxpayerType(shop?.type)}
                    name="name"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                  Número de teléfono
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.phoneNumber}
                    name="name"
                    disabled
                  />
                </div>
                <hr style={{ borderTop: "2px dotted #000" }} />
                <h6>Datos de ubicación</h6>
               
                <div className="col-md-4">
                  <label htmlFor="inputState" className="Form-label">
                    Provincia
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.province}
                    name="department"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputZip" className="Form-label">
                    Localidad
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.city}
                    name="department"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputZip" className="Form-label">
                    Dirección
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.address}
                    name="address"
                    disabled
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="inputZip" className="Form-label">
                    Código Postal
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.postalCode}
                    name="postalCode"
                    disabled
                  />
                </div>
            
                <hr style={{ borderTop: "2px dotted #000" }} />{" "}
                <h6>Datos del Representante legal</h6>
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                  Nombre de usuario
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.user}
                    name="name"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                  Nombre y Apellido
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.namePerson}
                    name="name"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                  CUIT
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.cuitPerson}
                    name="name"
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                  Número de teléfono
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.phoneNumberPerson}
                    name="name"
                    disabled
                  />
                </div>
               

                <hr style={{ borderTop: "2px dotted #000" }} />{" "}
                <h6>Declaración jurada</h6>
                <div className="col-md-4">
                  <label htmlFor="inputCity" className="Form-label">
                 Categoria
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={shop?.category}
                    name="name"
                    disabled
                  />
                </div>
                <label className="Form-check-label" htmlFor="gridCheck">
                  Fondos y valores aplicados provienen de
                  actividades lícitas
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    name="a1"
                    disabled
                    checked={shop?.licito}
                  />
                 
                </div>
                <label className="Form-check-label" htmlFor="gridCheck">
                 Persona Expuesta Politicamente
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    name="a2"
                    disabled
                    checked={shop?.pep}
                  />
               
                </div>
            
                <label className="Form-check-label" htmlFor="gridCheck">
                  Sujeto obligado ante UIF
                </label>
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    name="a3"
                    disabled
                    checked={shop?.uif}
                  />
                
                </div>
                
              </form>
             
            </div>
          ) : (
            <Spiner />
          )}
        </div>
      );
}

export default ShopDetail