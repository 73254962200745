import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import Swal from "sweetalert2";
import {
  state_BtnSimple,
  state_Tranfer,
  get_state_config,
} from "../redux/actions";
import { useDispatch } from "react-redux";

const Config = () => {
  const dispatch = useDispatch();
  const [check,setCheck]= useState(null)
  const [list, setList] = useState([]);
  const [amount, setAmount] = useState(null);
  const [amountBtn, setAmountBtn] = useState(null);
const [btnDisabled, setbtndisabled]= useState(false)
  useEffect(() => {
    
    setAmount()
    getStates();
  }, []);

  const getStates = async () => {
    const res = await dispatch(get_state_config());
    if (res?.status === 200) {
      setList(res?.msg);
      setCheck(res.msg[0]?.data.status)
      setAmount(res.msg[0]?.data?.amount)
    }
  };

  const updateAmountOnly = async (id,status) => {
    if (!amount) {
      Swal.fire({
        icon: "error",
        text: "Ingrese un monto para actualizar",
      });
      return;
    }

    const data = { id, amount: parseFloat(amount) ,status };
    try {
      const response = await dispatch(state_Tranfer(data));
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Monto actualizado correctamente",
        });
        getStates();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response?.msg,
        });
      }
    } catch (error) {
      console.error("Error updating amount:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el monto",
      });
    }
  };

  const updateState = async (id, newState, name) => {
   
    setCheck(newState);
    const data = { id, status: newState, amount: parseFloat(amount) };

    try {
      setbtndisabled(true)
      if (name === "Transferencias") {
        if (!amount && newState === true) {
          Swal.fire({
            icon: "error",
            text: "Ingrese un monto",
          });
          return;
        }
        const response = await dispatch(state_Tranfer(data));
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: `Se ${newState ? "habilitó" : "inhabilitó"} esta opción`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response?.msg,
          });
        }
      }

      if (name === "Boton Simple") {
        if (!amountBtn && newState === true) {
          Swal.fire({
            icon: "error",
            text: "Ingrese un monto",
          });
          return;
        }
        const response = await dispatch(state_BtnSimple(data));
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: `Se ${newState ? "habilitó" : "inhabilitó"} esta opción`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response?.msg,
          });
        }
      }
      getStates();
    } catch (error) {
      console.error("Error updating state:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el estado",
      });
    }finally{
      setbtndisabled(false)
    }
  };

  return (
    <div className="container">
      <h5 className="ms-3 mb-4">Configuraciones</h5>
      <div className="justify-content-start">
        {list
          .filter((el) => el.name === "Transferencias")
          ?.map((el, i) => (
            <div key={i}>
              <div className="p-3 d-flex align-items-center">
                <h5 style={{ fontSize: 18, marginRight: 20 }}>{el.name}</h5>
                <label htmlFor="">Monto:</label>
                <input
                  placeholder="Monto.."
                  className="form-control me-3"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type="text"
                  style={{ width: 200 }}
                />
                
                <label htmlFor="">Estado:</label>
                <Switch
                  onChange={(checked) => setCheck(checked)}
                  checked={check}
                />
                <label>{check ? "Activo" : "Inactivo"}</label>
                <button
                disabled={btnDisabled}
                  className="btn btn-primary me-3"
                  onClick={() => updateState(el._id,check, el.name)}
                >
                  Actualizar Monto
                </button>
              </div>
              <hr />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Config;


