import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reporte_saldos_cvu,
  reporte_fotos,
  Download_report_user,
} from "../../redux/actions";
import { FaRegFileExcel } from "react-icons/fa";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Paginado from "../Consola usuarios/Paginado";
const SaldosCvu = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reporte_cvu);
  const totalPage = useSelector((state) => state.total_report);
  const [selectDate, setSelectedDate] = useState("");
  const [res, setRes] = useState("Selecciona una fecha");
  const [listDownload, setlistDownload] = useState([]);
  const [listRec, setlistRec] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {}, [dispatch, selectDate, res, page, listDownload, listRec]);

  const buscar = async () => {
    // setPage(1);
    const res = await dispatch(
      reporte_saldos_cvu({ date: selectDate, page: page })
    );
    const download = await dispatch(Download_report_user({ date: selectDate }));
    const rec = await dispatch(reporte_fotos({ date: selectDate }));
    //console.log(res)
    if (res?.status === 400) {
      setRes("No se encontraron datos");
    } else {
      setRes("");
    }

    if (download?.status === 200) {
      setlistDownload(download?.msg);
    }
    if (rec?.status === 200) {
      setlistRec(rec?.msg[0]);
    }
    //console.log(listRec.data);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(reporte_saldos_cvu({ date: selectDate, page: newPage }));
  };

  const exportExcel = () => {
    if (!listDownload || listDownload?.length === 0) {
      alert("No hay datos para exportar");
      return;
    }

    // Crear el contenido de la hoja
    const worksheetData = listDownload?.map((el) => ({
      Fecha: new Date(el.metadata?.date).toLocaleDateString("es-AR"),
      CVU: el.metadata.cvu,
      CUIT: el.metadata.cuit.toString(),
      Saldo: el.metadata.currentBalance,
      CódigoPostal: el.metadata.postalCode,
      Estado: el.metadata.state,
    }));
    // Crear la hoja de trabajo en formato JSON
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = {
      Sheets: { "Reporte de Saldos": worksheet },
      SheetNames: ["Reporte de Saldos"],
    };

    // Generar el archivo Excel
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    FileSaver.saveAs(blob, `Reporte_Saldos_CVU_${selectDate}.xlsx`);
  };

  return (
    <div className="container">
      <div className="d-flex align-items-center p-2 mb-3">
        <label htmlFor="">Seleccionar fecha:</label>
        <input
          type="date"
          className="form-control"
          style={{ width: 300 }}
          value={selectDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <button
          onClick={buscar}
          className="btn ms-2"
          style={{ backgroundColor: "var(--naranja)", color: "white" }}
        >
          Buscar
        </button>
      </div>
      <nav className="ms-3">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Usuarios
          </button>
          <button
            className="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Cuenta recaudadora
          </button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
          tabindex="0"
        >
          {!res ? (
            <>
              <table className="table ms-3">
                <thead>
                  <tr>
                    <th scope="col">Fecha</th>
                    <th scope="col">CUIT</th>
                    <th scope="col">CVU</th>
                    <th scope="col">Saldo</th>
                  </tr>
                </thead>
                {data.msg?.map((el, i) => (
                  <tbody key={i}>
                    <tr>
                      <td>
                        {new Date(el.metadata.date).toLocaleDateString("es-AR")}
                      </td>
                      <td>{el.metadata.cuit}</td>
                      <td>{el.metadata.cvu}</td>
                      <td>{el.metadata.currentBalance.toFixed(2)}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
              {!res && (
                <button className="btn" onClick={exportExcel}>
                  <FaRegFileExcel style={{ fontSize: 26 }} /> Descargar Excel
                </button>
              )}
              <Paginado
                data={totalPage}
                pagina={page}
                setPagina={handlePageChange}
              />
            </>
          ) : (
            <div className="alert alert-info" role="alert">
              {res}
            </div>
          )}
        </div>
      </div>

      <div
        className="tab-pane fade"
        id="nav-profile"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
        tabindex="0"
      >
        {listRec && listRec?.data ? (
          <>
            {listRec?.data?.map((el) => (
              <div
                className="card p-4 mb-3"
                key={el.id}
                style={{
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  maxWidth: "400px",
                }}
              >
                <p
                  className="fs-4 fw-bold mb-2"
                  style={{ color: "var(--naranja)" }}
                >
                  {el.owners[0]?.display_name}
                </p>
                <p className="fs-5 text-muted mb-1">
                  {new Date(listRec?.date).toLocaleDateString("es-AR")}
                </p>
                <p className="fs-5 text-muted mb-1">{el.type}</p>
                <p className="fs-6">
                  CBU:{" "}
                  <span className="fw-bold">{el.account_routing?.address}</span>
                </p>

                <div
                  className="card p-3 mt-3"
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#f8f9fa",
                    width: "100%",
                  }}
                >
                  <p className="fs-5 m-0">
                    Balance:{" "}
                    <span className="fw-bold text-success">
                      $ {el.balance.amount}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="alert alert-info" role="alert">
            Sin datos
          </div>
        )}
      </div>
    </div>
  );
};

export default SaldosCvu;
