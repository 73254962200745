import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Spiner } from "../Spiner";
import Paginado from "../Consola usuarios/Paginado";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { bussine_transactions } from "../../redux/actions";
const MovimeintosBussine = (body) => {
  const mov = useSelector((state) => state.moviments_bussine);
  const total = useSelector((state) => state.totalMovBussine);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (body) {
      const fetchMovements = async () => {
        try {
          setLoading(true);
          const data = {
            cuit: body.cuit,
            page: page,
          };
          // Ejecutar la acción para obtener los movimientos
          await dispatch(bussine_transactions(data));
        } catch (error) {
          console.error("Error fetching users:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchMovements(); // Llamar la función async
    }
  }, [page]);
  return (
    <div>
      <div className="pb-2 d-flex align-items-center">
        <div className="d-flex align-items-center ms-3">
          <Link onClick={body.closeModaldetail}>
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange" }}
            />
          </Link>
        </div>
      </div>
      <p className="fs-3 ms-3">Movimientos</p>
      <div id="#movimientos" style={{ padding: "20px" }}>
        <div>
          {loading ? (
            <Spiner />
          ) : !mov || mov?.length === 0 ? (
            <div className="alert alert-warning" role="alert">
              No se encontraron movimientos.
              <BiSearch style={{ fontSize: "30px" }} />
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Nombre Oigen</th>
                  <th scope="col">Nombre de destino</th>
                  <th scope="col">Cuit Destino</th>
                  <th scope="col">CVU Destino</th>
                  <th scope="col">Monto(AR$)</th>
                </tr>
              </thead>
              <tbody>
                {mov?.map((el) => (
                  <tr key={el._id}>
                    <td>{el.movimiento?.nameOrig}</td>
                    <td>{el.movimiento?.nameDest}</td>
                    <td>{el.movimiento?.cuitDest}</td>
                    <td>{el.movimiento?.cvuDest}</td>
                    <td>{el.movimiento?.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <Paginado pagina={page} data={total} setPagina={setPage} />
      </div>
    </div>
  );
};

export default MovimeintosBussine;
