import React, { useState ,useEffect} from "react";
import { useSelector } from "react-redux";
import { IoArrowBackCircleOutline} from "react-icons/io5";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { conciliar_excec,conciliar_transfer } from "../../redux/actions";
import Swal from "sweetalert2";
import { Spiner } from "../Spiner";
import { Alert } from "reactstrap";
import { IoIosCloseCircle } from "react-icons/io";
const ConciliacionDetalle = ({closeModal,id}) => {
  const data = useSelector((state) => state.conciliacion_detail);
  const [loading,setLoading]= useState("Realizar Conciliación")
  const [btndisabled,setBtnDisabled]= useState(false)
  const dispatch = useDispatch()
  const utcDate = new Date(data?.mongo?.timestamp); // Fecha en formato UTC
  const localDate = new Date(utcDate.toLocaleString()); 
  
  useEffect(() => {
    if (data?.mongo) {
        setBtnDisabled(true);
    } else {
        setBtnDisabled(false);  // Dependiendo de tu lógica
    }
  // console.log(data)
}, [data, id]);  

const RealizarConciliacion = async () => {

    const result = await Swal.fire({
        title: "Estas seguro que quieres realizar esta acción?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar"
    });

    if (result.isConfirmed) {
       confirm()
    }
};

const confirm =async()=>{
    try {
        setLoading("Procesando...");
        setBtnDisabled(true);
        
        const body = {
            origen: data?.bind?.origen,
            id: data?.bind?.id[0],
            cvuDest: data?.bind?.destino?.origin_credit?.cvu,
            amount: data?.bind?.monto?.amount
        };
        
        const response = await dispatch(conciliar_excec(body));
        
        if (response?.status === 200) {
            Swal.fire({
                title: "Operación realizada con éxito",
                icon: "success"
            });
            //setBtnDisabled(true)
        } else {
            Swal.fire({
                title: response?.data?.msg,
                icon: "error"
            });
        }
    } catch (error) {
        console.error(error);
    } finally {
        setLoading("Realizar Conciliacion");
        setBtnDisabled(false);
    }
}


  return (
    <>
   

    <div className="container card mt-2" >

            <Link
            to={"/list/transacciones"}
            onClick={ closeModal}
            style={{ padding: 8 }}
          >
            <IoIosCloseCircle  style={{ fontSize: "30px", color: "salmon" }}/>
           
          </Link>


      <div className="row row-cols-2">
        <div className="col">
          <p
            className="fs-5 text-white p-2 text-center"
            style={{ backgroundColor: "var(--naranja)" }}
          >
           BANK
          </p>
          {!data ? (
            <div class="alert alert-info" role="alert">
              Sin datos
            </div>
          ) : (
            <>
              <h5>Código transacción: </h5>
              <p>{data?.bind?.id}</p>
              <h5>Monto:</h5>
              <p>
                {data?.bind?.monto?.amount} {data?.bind?.monto?.currency}
              </p>
              <h5>Origen:</h5>
              <p>{data?.bind?.origen?.name}</p>
              <p>cuit: {data?.bind?.origen?.id}</p>
              <p>cvu: {data?.bind?.origen.account_routing?.address}</p>
              <hr />
              <h5>Destino:</h5>
              <p>cuit: {data?.bind?.destino?.origin_credit?.cuit || "-"}</p>
              <p>cvu: {data?.bind?.destino?.origin_credit?.cvu}</p>
            </>
          )}
        </div>
        <div className="col">
          <p
            className="fs-5 text-white p-2 text-center"
            style={{ backgroundColor: "var(--naranja)" }}
          >
           SUSA
          </p>
          {!data?.mongo ? (
            <div className="alert alert-info" role="alert">
              Sin datos
            </div>
          ) : (
            <>
            <h5>Fecha de creación</h5>
              <p>{localDate.toLocaleString()}</p>
              <h5>Código transacción: </h5>
              <p>{data?.mongo?.metadata?.idMensaje}</p>
              <h5>Origen: </h5>
              <p>cvu: {data?.mongo?.metadata?.cvuOrigin}</p>
              <h5>Destino: </h5>
              <p>cvu: {data?.mongo?.metadata?.cvuDest}</p>
              <h5>Estado</h5>
              <p>{data?.mongo?.metadata?.status}</p>
            </>
          )}
        </div>
      </div>
      <div className="d-flex" style={{  justifyContent:"center"}}>

      <button disabled={btndisabled} className="btn btn-primary m-3" onClick={RealizarConciliacion}>{loading}</button>
      </div>
    
    </div>
   
    
    </>
  );
};

export default ConciliacionDetalle;
