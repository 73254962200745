import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const DetalleMovimiento = ({ closeModal }) => {
  const mov = useSelector((state) => state.one_transfer);
  useEffect(() => {
    //console.log(mov);
  }, []);
  return (
    <div className="container mt-4 ms-4">
      <div className="card text-justify " style={{ width: 900 }}>
        <div className="d-flex align-items-center ms-3 p-2">
          <Link onClick={closeModal}>
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange" }}
            />
          </Link>
        </div>
        <h4
          style={{
            backgroundColor: "var(--naranja)",
            padding: 10,
            color: "white",
            textAlign: "center",
          }}
        >
          Detalle del Movimiento
        </h4>
        <div className="ms-3 justify-content-center p-3">
          <h5 className=" pb-3">Datos de transacción </h5>
          <div className="col d-flex mb-3">
            <div style={{ marginRight: "20%" }}>
              <p htmlFor="">
                Fecha de operación: {mov.movimiento.dateOperation || "-"}
              </p>
              <p htmlFor="">Monto: ${mov.movimiento.balance}</p>
              <p htmlFor="">Observación: {mov.movimiento.observations}</p>
            </div>
            <div>
              <p htmlFor="">Metodo: {mov.movimiento.wayToPay || "-"} </p>

              <p htmlFor="">Tipo: {mov.movimiento.types}</p>
              {
                mov.movimiento?.status && (  <p htmlFor="">Estado: {mov.movimiento?.status}</p>)
              }
            
            </div>
          </div>
          <div className="col mb-3 ">
            <h5 className=" pb-3">Datos de Origen </h5>
            <p htmlFor="">
              Nombre: {mov.movimiento.nameOrig || "-"} {mov.movimiento?.lastNameOrig}
            </p>
            <p htmlFor="">CUIT: {mov.movimiento.cuitOrig || "-"}</p>
            <p htmlFor="">CVU: {mov.movimiento.cvuOrig || "-"}</p>
          </div>
          <div className="col mb-3 ">
            <h5 className=" pb-3">Datos de Destino </h5>
            <p htmlFor="">
              Nombre: {mov.movimiento.nameDest || "-"} {mov.movimiento?.lastNameDest}
            </p>
            <p htmlFor="">CUIT: {mov.movimiento.cuitDest}</p>

            <p htmlFor="">CVU: {mov.movimiento.cvuDest || "-"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalleMovimiento;
