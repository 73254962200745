import React from "react";
import { useState, useEffect ,useRef} from "react";
import { useDispatch } from "react-redux";
import {
  change_postal_code,
  get_list_Onboarding,
  update_state_Onboarding,
} from "../../redux/actions";
import Switch from "react-switch";
import Swal from "sweetalert2";

const CargosLey = ({_id, cp}) => {
  const [checked, setChecked] = useState(null);
  const [data, setData] = useState({});
  const [id,setId]= useState("")
  const [cpa, setCpa]= useState("")
  const dispatch = useDispatch();
  const hasFetched = useRef(false);// almacena si la petición ya se ha realizado, evitando repetirla si el valor id no cambia.

  useEffect(() => {
    // Si _id cambia y es distinto del id actual, actualiza id y ejecuta la petición
    if (_id && _id !== id) {
      setId(_id);
      hasFetched.current = false; // Resetear ref para permitir la petición
    }
    setCpa(cp)
  }, [_id, cp]);

  useEffect(() => {
    if (id && !hasFetched.current) {
      // Ejecutar getOnboarding una vez id esté disponible
      getOnboarding();
      hasFetched.current = true;
    }
  }, [id]); // Depender solo de id

  const getOnboarding = async () => {
    let res = await dispatch(get_list_Onboarding({_id:id}));
    if (res?.status === 200) {
      //console.log(res.msg)
      setData(res?.msg);
      setChecked(res.msg?.atc);
    }else {
      setData([])
    }
  };
  const handleChangeState = async (cuit,check) => {
    setChecked(check)
    const body = {
      cuit: cuit,
      state: check,
    };
    let res = await dispatch(update_state_Onboarding(body));
    if(res.status==200){
      Swal.fire({
       title:res?.msg || "Estado actualizado correctamente!",
       icon:"success"
      })
    }else{
      Swal.fire({
        title:res?.msg || "Ocurrió un error !",
        icon:"error"
       })
    }
   // console.log(res)
  };

  const updateCPA =async()=>{
    if(cpa.length >8){
      Swal.fire({
        title:"El código no puede contener más de 8 carácteres",
        icon:"warning"
       })
       return
    }
    const body={
      postalCode:cpa,
      _id:id
   
    }
    const res = await dispatch(change_postal_code(body))
    if(res.status==200){
      Swal.fire({
       title:res?.msg || "Código postal actualizado correctamente!",
       icon:"success"
      })
    }else{
      Swal.fire({
        title:res?.msg || "Ocurrió un error !",
        icon:"error"
       })
    }
  }
  return (
    <div>
      <div className="card" style={{ padding: "20px" }}>
        {data && data?.intentos ? (
          <>
            <h5 className="mb-3">Permitir registro</h5>
          <div className="d-flex">
            <label   htmlFor="" className="fs-5 me-3"> Intentos realizados: {data?.intentos} </label>
            <Switch
              onChange={(checked) => handleChangeState(data.cuit,checked)}
              checked={checked}
            />
            <label
              className="form-check-label fs-5"
              htmlFor="flexSwitchCheckDefault"
            >
              {checked ? "Activo" : "Inactivo"}
            </label>
          </div>
          
          </>
        ) : (
          <div className="alert alert-info" role="alert">
           Usuario sin alertas
          </div>
        )}
        <br />
      </div>
      <div className="card mt-3 p-3 ">
         <h5 htmlFor="">Actualizar código postal</h5>
         <div className="d-flex align-items-center mt-3">
          <label className="fs-5" htmlFor="">Código Postal: </label>
         <input maxLength={8} value={cpa} onChange={(e)=> setCpa(e.target.value)} className="form-control" type="text"  style={{width:300}}/>
          <button
            onClick={updateCPA}
            className="btn  btn-outline-success"
            style={{ marginLeft: "10px" }}
          >
            Guardar
          </button>
         </div>
         <a className="ms-3 mt-3" target="_blank" href="https://www.correoargentino.com.ar/formularios/cpa">Consulta de CPA</a>
      </div>    
    </div>
  );
};

export default CargosLey;
