import React, { useState, useEffect } from "react";
import styles from "../styles/Login.module.css";
import Swal from "sweetalert2";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import AuthService from "../helpers/AuthService";
import { LoginSuccess, LogOut, getUsers ,sendCodePass, recoverPass} from "../redux/actions";
import { useDispatch } from "react-redux";
import logo from "../img/susa-logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [usuario, setUsuario] = useState({
    user: "",
    password: "",
  });
  const navigate = useNavigate();
  const existingUserLog = JSON.parse(window.localStorage.getItem("user-log"));
  const dispatch = useDispatch();
  const [seePassword, setSeePassword] = useState(false);
  const [modalPass, setModalPass] = useState(false);
  const [modalRecoverpass, setModalRecoverPass] = useState(false);
  const [email,setEmail]= useState("")
  const [changePass,setChangepass]=useState({
    cod:"",
    newpass:""
  })
  useEffect(() => {
   
  }, []);

  const handleUser = (e) => {
    setUsuario({ ...usuario, [e.target.name]: e.target.value });
    // console.log(usuario);
  };

  const handlePass = (e) => {
    setChangepass({ ...changePass, [e.target.name]: e.target.value });
    //console.log(changePass);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await AuthService.login(usuario);
      //console.log("respuesta", response);

      const data = {
        data_user: response?.data?.user,
        token: response?.data?.token,
      };

      if (!usuario?.password || !usuario?.user) {
        return Swal.fire({
          icon: "error",
          title: "Error",
          text: "Debe rellenar todos los campos",
        });
      }

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Bienvenido!",
          text: "¡Has iniciado sesión exitosamente!",
          confirmButtonText: "Continue",
        });
        dispatch(LoginSuccess(data));
        navigate("/home");
       
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: response?.data?.msg, // Mostrar el mensaje de error
          confirmButtonText: "Continue",
        });
      }
    } catch (error) {
      // console.log(error.message);
      // Mostrar el mensaje de error en el bloque catch
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.message,
        confirmButtonText: "Continue",
      });
    }
  };

  const enviarCorreo =async()=>{
    let correo={
      email: email.toLowerCase()
    }
    const response = await dispatch(sendCodePass(correo))
    if(response.status ===200){
      Swal.fire({
        icon: "success",
        text: response?.msg ||"se envio un código a su correo",
        confirmButtonText: "Continue",
      });
      setTimeout(() => {
        setModalRecoverPass(true)
      }, 500);
    }
  }

  const cambiarContraseña =async()=>{
    const changepassdata={
    cod:changePass.cod.trim(),
     newpass:changePass.newpass.trim()
    }
  
    const response = await dispatch(recoverPass(changepassdata))
    if(response.status ===200){
      Swal.fire({
        icon: "success",
        text: response?.msg ||"Contraseña actualizada correctamente",
        confirmButtonText: "Continue",
      });
      setTimeout(() => {
        setModalRecoverPass(false)
        setModalPass(false)
      
      }, 500)
    }else{
      Swal.fire({
        icon: "error",
        text: response?.msg ||"ocurrió un error",
        confirmButtonText: "Continue",
      });
    }
  }
const cerrarModals =()=>{
  setModalRecoverPass(false)
  setModalPass(false)
}

  return (
    <div className={styles.container}>
       {/* MODAL CAMBIAR CONTRASEÑA  */}
       {modalPass === true ? (
        <div className="card p-3 mt-5" style={{width:"30%"}}>
          {
            modalRecoverpass ? (
              <>
              <div className="modal-dialog">
              <h5 className="modal-title">Recuperar contraseña</h5>
            </div>
            <div className="modal-body p-3">
              <p className="text-center">Ingrese el código que le enviamos a su correo:</p>
              <input className="form-control" type="text" name="cod" value={changePass.cod} onChange={handlePass}/>
              <p className="text-center">Ingrese una nueva contraseña:</p>
              <input className="form-control" type="text" name="newpass" value={changePass.newpass} onChange={handlePass}/>
            </div>
            <div className="modal-footer align-items-center  ">
              <button type="button" className="btn btn-primary me-2" onClick={cambiarContraseña}>
                Guardar
              </button>
              <button
              type="button"
              class="btn btn-secondary "
              onClick={cerrarModals}
            >
              Salir
            </button>
            </div>
              </>
            ):(
              <>
          <div className="modal-dialog">
            <h5 className="modal-title">Recuperar contraseña</h5>
          </div>
          <div className="modal-body p-3">
            <p className="text-center">Ingrese su correo, le enviaremos un código de recuperación</p>
            <input className="form-control" type="text" name="email" value={email}onChange={(e)=>setEmail(e.target.value)}/>
          </div>
          <div className="modal-footer align-items-center  ">
            <button type="button" className="btn btn-primary me-2" onClick={enviarCorreo}>
              Enviar
            </button>
            <button
              type="button"
              className="btn btn-secondary "
              onClick={() => setModalPass(false)}
            >
              Cancelar
            </button>
          </div>
              </>
            )
          }
        </div>
      ):
      <form className={styles.formLogin} onSubmit={handleSubmit}>
        <div className={styles.container_logo}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "50%", marginBottom: "30px", padding: "5px" }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Usuario
          </label>
          <input
            type="text"
            id="user"
            name="user"
            className="form-control"
            value={usuario?.user}
            onChange={handleUser}
          />
        </div>
        <div className="mb-3" style={{ position: "relative" }}>
          <label htmlFor="exampleInputPassword1" className="form-label">
            Contraseña
          </label>
          <input
            value={usuario?.password}
            name="password"
            type={seePassword ? "text" : "password"}
            className="form-control"
            onChange={handleUser}
          />
          <div
            className={styles.ojito}
            onClick={() => {
              setSeePassword(!seePassword);
            }}
          >
            {seePassword ? <BsEyeSlash /> : <BsEye />}
          </div>
        </div>
        {/* mas adelante agregar captcha */}
        <br />
        <div className="d-grid justify-content-center ">
          <button className="btn text-white" type="submit">
            Ingresar
          </button>

          <p
            type="button"
            onClick={() => setModalPass(true)}
            className="btnpass fs-6 mt-4 text-center "
          >
            ¿Olvidaste tu contraseña?
          </p>
        </div>
      </form>
     
    
    }
    </div>
  );
};

export default Login;
