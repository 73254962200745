import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  search_User,
  search_by_validation,
  search_by_name,
  search_User_asociado,
  search_User_asociado_status,
  search_User_asociado_name,
} from "../../redux/actions";
import "../../styles/Navbar.module.css";

const Navbar = ({ setPagina, Asociados }) => {
  const [search, setSearch] = useState(""); // Estado inicializado
  const [name, setName] = useState(""); // Estado inicializado
  const [lastName, setLastName] = useState(""); // Estado inicializado

  const error = useSelector((state) => state.error);
  const [textBtn, setTextBtn] = useState("Buscar");
  const dispatch = useDispatch();

  useEffect(() => {
   // console.log(Asociados);
  }, [search, Asociados]);

  const buscarUsuario = async (e) => {
    e.preventDefault();
    setTextBtn("Buscando..");

    try {
      if (search) {
        
        dispatch(search_User(parseInt(search)));
        setPagina(1);
      
        return;
      
      } else if (name && lastName) {
        const body = {
          name: name?.toUpperCase(),
          lastName: lastName?.toUpperCase(),
          page: 1,
        };

        dispatch(search_by_name(body));
        setPagina(1);
      
      }
    
    } catch (error) {
      //console.log(error.message);
    } finally {
      setTextBtn("Buscar");
    }
  };
  const buscarUsuarioAsociado = (e) => {
    //console.log("entro aca");
    e.preventDefault();
    setTextBtn("Buscando..");
    try {
      if (search && Asociados) {
        dispatch(search_User_asociado(parseInt(search)));
        setPagina(1);
      } 
       else if (name  && lastName && Asociados) {
        const body = {
          name: name?.toUpperCase(),
          lastName: lastName?.toUpperCase(),
          page: 1,
        };
        dispatch(search_User_asociado_name(body));
        setPagina(1);

      }

   
    } catch (error) {
      // console.log(error.message);
    } finally {
      setTextBtn("Buscar");
    }
  };

  return (
    <nav className="containerNav">
      <div
        className={`row pt-2`}
        role="search"
      
      >
        <div className="col-md-6 col-lg-2">
          <label htmlFor="dni" style={{ marginRight: "5px", fontSize: "14px" }}>
            DNI:
          </label>
          <input
            className={`form-control `}
            type="number"
            min={0}
            placeholder=""
            aria-label="Search"
            value={search}
            name="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <label htmlFor="label">Nombres:</label>
          <input
            className={`form-control`}
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <label htmlFor="label">Apellidos:</label>
          <input
            className={`form-control`}
            value={lastName}
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      
        <div className="col-lg-2 d-flex justify-content-center">
          <button
              onClick={(e) => {
                if (Asociados) {
                  buscarUsuarioAsociado(e); // Llama a la función correcta si hay Asociados
                } else {
                  buscarUsuario(e);
                }
              }}
            style={{
              border: "solid 1px var(--naranja)",
              margin: "20px",
              fontSize: "14px",
            }}
            className=" btn btn-outline btn-lg"
            type="submit"
          >
            {textBtn}
          </button>
        </div>
      </div>
      <hr className={"dottedHr"}></hr>
    </nav>
  );
};

export default Navbar;
