import React from 'react'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { useEffect ,useState} from 'react'
import { revertir } from '../../redux/actions'
import styles from "../../styles/Users.module.css";

const Reverso = () => {
    const dispatch = useDispatch()
     const [id,setId]= useState()
     const [disabledBtn,setDisabledBtn]= useState(false)

    const rever =()=>{
      if(!id){
        Swal.fire({
          title:"Ingrese un Id",
          icon:"warning"
        })
        return
      }
        Swal.fire({
            title: "¿Seguro que quiere realizar esta acción?",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "Cancelar",
            preConfirm: async () => { // Deshabilitará el botón al iniciar la promesa
              setDisabledBtn(true); // Deshabilitar el botón externo
              const res = await dispatch(revertir({ id: id }));
          
              if (res?.status === 200) {
                Swal.fire(res?.msg, "", "success");
                setDisabledBtn(false);
                return true; 
              } else if (res?.status === 500) {
                Swal.fire(res?.msg, "", "error");
                setDisabledBtn(false);
                return false;
              } else {
                Swal.fire(res?.msg, "", "warning");
                setDisabledBtn(false);
                return false;
              }
            }
          }).then((result) => {
            if (result.isConfirmed) {
              return
            }
          });
          
    }
  return (
    <div className='container'>
         <h5 className='ms-3 mb-4'>Reverso Bóton de pago</h5>
        <div  className={`${styles.reverso} d-flex align-items-center`}>
            <label htmlFor="">Id:</label>
            <input value={id} onChange={(e)=> setId(e.target.value)} type="text"  className='form-control' style={{width:300}}/>
            <button disabled={disabledBtn} onClick={rever} className='btn ms-2' style={{backgroundColor:"var(--naranja)",color:"white"}}>Reversar</button>
        </div>
    </div>
  )
}

export default Reverso