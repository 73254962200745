import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAdmin,
  search_Admin_by_name,
  search_Admin_by_dni,
  search_admin_state,
} from "../../redux/actions";
const Search_ope = () => {
  const [admin, setAdmin] = useState({
    name: "",
    dni: "",
    state: "",
  });

  const dispatch = useDispatch();
  const HandleChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (admin.name) {
      dispatch(search_Admin_by_name(admin.name));
      //setAdmin((prevAdmin) => ({ ...prevAdmin, name: "" }));
    } else if (admin.dni) {
     // console.log(admin);

      dispatch(search_Admin_by_dni(admin.dni));
      //setAdmin((prevAdmin) => ({ ...prevAdmin, dni: "" }));
    } else if (admin.state) {
      dispatch(search_admin_state(admin.state));
     // console.log(admin);
     // setAdmin((prevAdmin) => ({ ...prevAdmin, state: "" }));
    }
  };
  return (
    <div className="">
      <form
        className="row "
        action=""
        onSubmit={HandleSubmit}
        style={{ display: "flex", padding: "10px", alignItems: "center" }}
      >
        <div className="col-sm-6 col-lg-3">
          <label htmlFor="name" style={{ marginRight: "5px" }}>
            Nombres:
          </label>
          <input
            className="form-control me-1"
            type="text"
            value={admin.name}
            name="name"
            onChange={HandleChange}
          />
        </div>
        <div className="col-sm-6 col-lg-3">
          <label htmlFor="dni" style={{ marginRight: "5px" }}>
            DNI:
          </label>
          <input
            className="form-control me-1"
            type="number"
            min={0}
            value={admin.dni}
            name="dni"
            onChange={HandleChange}
          />
        </div>
        <div className="col-lg-3">
          <label htmlFor="estado" style={{ marginRight: "5px" }}>
            Estado:
          </label>
          <select
            className="form-select"
            style={{ fontSize: "20px" }}
            aria-label="Default select example"
            name="state"
            id=""
            value={admin.state}
            onChange={HandleChange}
          >
            <option hidden>Seleccionar</option>
            <option value="todos">Todos</option>
            <option value="activo">Activos</option>
            <option value="inactivo">Inactivos</option>
          </select>
        </div>
        <div className="col-lg-3 mt-3">
          <button
            className="btn  btn-outline btn-lg "
            style={{
              border: "solid 1px var(--naranja",
              margin: "20px",
              fontSize: "14px",
            }}
          >
            Buscar
          </button>
        </div>
      </form>
      <hr className={"dottedHr"}></hr>
    </div>
  );
};

export default Search_ope;
