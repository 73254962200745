import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_alert,
  get_alert_active,
  get_alert_atendidas,
  get_alert_state,
} from "../redux/actions";
import Modal from "react-modal";
import styles from "../styles/Users.module.css";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { HiOutlineBellAlert, HiOutlineIdentification } from "react-icons/hi2";
import { FaRegUser ,FaPhoneSquare} from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import Swal from "sweetalert2";
import Paginado from "./Consola usuarios/Paginado";
function Alert() {
  const dispatch = useDispatch();
  const [pageAct, setPageAct] = useState(1);
  const [pageAtend, setPageAtend] = useState(1);

  const [selectedState, setSelectedState] = useState("");
  const [selectedRisk, setSelectedRisk] = useState("");
  const [monto, setMonto] = useState("");
  const [obs, setObs] = useState("");
  const [id, setId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const alerts = useSelector((state) => state.alerts_active);
  const totalAlertAct =useSelector(state=> state.totalAlertAct)
  const totalAlertAt =useSelector(state=> state.totalAlertAt)

  const atendidas = useSelector((state) => state.alerts_atendidas);
  const [user, setUser] = useState([]);
  const atender = (body) => {
    setId(body._id);
    setUser(body);
    setTimeout(() => {
      setOpenModal(true);
    }, 300);
  };

  const Confirmar = async () => {
    if(!selectedRisk || !selectedState || !monto || !obs){
        Swal.fire({
            text: `Completar los campos solicitados`,
            icon: "warning",
          });
          return
    }

    const body = {
      _id: id,
      state: selectedState,
      risk: selectedRisk,
      observation: obs,
      allowedAmount: monto,
    };

    const res = await dispatch(get_alert_state(body));
    if(res.status===200){
        Swal.fire({
            text: `Alerta atendida`,
            icon: "success",
          });
    }
    dispatch(get_alert_active({ page:1 }));
    dispatch(get_alert_atendidas({ page:1 }));

    setSelectedRisk("")
    setSelectedState("")
    setMonto("")
    setObs("")
  };
  useEffect(() => {
    dispatch(get_alert_active({ page: pageAct }));
    dispatch(get_alert_atendidas({ page: pageAtend }));
    //console.log(atendidas);
   // console.log(user);
  }, [user]);
  const handlePageChange = (newPage) => {
    setPageAtend(newPage); // Cambiar la página
  };
  const handlePageChangeAlertActivas =(newPage)=>{
  setPageAct(newPage)
  }


  function convertirHoraArgentina(utcDate) {
    const date = new Date(utcDate);
    // Sumamos las horas de diferencia (-3 horas de desfase)
    date.setHours(date.getHours() - 3);
    return date.toISOString().replace('T', ' ').substring(0, 19); // Para mostrar en formato deseado
  }
  return (
    <div className="container">
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Alertas Activas <HiOutlineBellAlert color="red" />
          </button>
          <button
            className="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Alertas Atendidas <HiOutlineBellAlert color="green" />
          </button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
          tabindex="0"
        >
          <div>
            <h4 className="text-danger p-2">Alertas Activas</h4>
            {alerts.length ? (
              <table className={`table `}>
                <thead className="table-info">
                  <tr>
                    <th>Fecha</th>
                    <th>Usuario</th>
                    <th>CUIT</th>
                    <th>Observación</th>
                    <th>Riesgo</th>
                    <th>Monto</th>
                    <th>Estado</th>
                    <th>Atender</th>
                  </tr>
                </thead>

                <tbody>
                  {alerts?.map((alert, index) => (
                    <tr key={index}>
                        <td> {(alert.date?.replace('T', ' ').replace('Z', '').split('.')[0])}</td>
                      <td>
                        {alert.usuario?.name} {alert.usuario?.lastName}
                      </td>
                     <td>{alert.usuario.cuit}</td>
                      <td>{alert.observation}</td>
                      <td>{alert.risk}</td>
                      <td>{alert.amount}</td>
                      <td>{alert.state}</td>
                      <td>
                        <button onClick={() => atender(alert)}>Atender</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            
            ) : (
              <div className="alert alert-info" role="alert">
                Sin alertas activas
              </div>
            )}
               <Paginado  
               data={totalAlertAct}
               pagina={pageAct}
               setPagina={handlePageChangeAlertActivas}>
               </Paginado>
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
          tabindex="0"
        >
          {/* inactivas */}
          <div>
            <h4 className="text-success p-2">Alertas Atendidas</h4>
            <table className={`table `}>
              <thead className="table-info">
                <tr>
                  <th>Fecha</th>
                  <th>Usuario</th>
                  <th>CUIT</th>
                  <th>Observación</th>
                  <th>Riesgo</th>
                  <th>Monto</th>
                  <th>Estado</th>
                  <th>Atender</th>
                </tr>
              </thead>
              <tbody>
                {atendidas.map((alert, index) => (
                  <tr key={index}>
                 <td> {(alert.date?.replace('T', ' ').replace('Z', '').split('.')[0])}</td>

                    <td>
                      {alert.usuario?.name} {alert.usuario?.lastName}
                    </td>
                    <td>{alert.usuario.cuit}</td>
                    <td>{alert.observation}</td>
                    <td>{alert.risk}</td>
                    <td>{alert.amount}</td>
                    <td>{alert.state}</td>
                    
                    <td>
                      <button  onClick={() => atender(alert)}>Atender</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginado  
            data={totalAlertAt}
            pagina={pageAtend}
            setPagina={handlePageChange}>
            </Paginado>
          </div>
        </div>
      </div>

      {/* MOdal alert */}
      <Modal
        isOpen={openModal}
        className={styles.modal}
        // overlayClassName={styles.Overlay}
        // onRequestClose={closeModalMov}
        contentLabel="Movimientos"
      >
        <div className="d-flex align-items-center ms-3 p-2">
          <Link onClick={() => setOpenModal(false)}>
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange" }}
            />
          </Link>
        </div>
        <div className="container" style={{ marginLeft: "10%" }}>
     
          <div className="card p-3 mb-2" style={{ width: 800 }}>
            <h5 className="pb-3"> Datos del usuario:</h5>
            <div className="d-flex">
            <p>
              <FaRegUser color="orange" /> {user.usuario?.name}{" "}
              {user.usuario?.lastName}
            </p>

            <p className="ms-4">
              <HiOutlineIdentification color="orange" /> {user.usuario?.cuit}
            </p>
            </div>
            <div className="d-flex">

            <p ><MdAlternateEmail color="orange"/> {user.usuario?.email}</p>
            <p className="ms-4"><FaPhoneSquare color="orange"/> {user.usuario?.phone}</p>
            </div>
            <h5 className="pb-3"> Observación:</h5>
            <p>{user.observation}</p>
          </div>
          {
            user.state ==="Atendido"?(
                <div style={{ width: 800 , marginTop:40}} className="alert alert-success" role="alert">
               Alerta atendida
              </div>
            ):(

          <div className="card p-4" style={{ width: 800 , marginTop:40}}>
          <h5 className="text-center mb-4">Completar formulario</h5>
          <div className="d-flex justify-content-center">
              <label htmlFor="">Observación</label>
              <input

                value={obs}
                onChange={(e) => setObs(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>
            <div className="d-flex mt-3">
              <label htmlFor="">Monto Permitido $</label>
              <input
      
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
                type="text"
                className="form-control"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
                
              <select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="form-control col-4 mb-3 me-3"
                style={{ maxWidth: 300 }}
              >
                <option value="">Seleccione un estado de alerta</option>
                <option value="Activo">Activo</option>
                <option value="Inactivo">Inactivo</option>
                <option value="Pendiente">Pendiente</option>
              </select>

              <select
                value={selectedRisk}
                onChange={(e) => setSelectedRisk(e.target.value)}
                className="form-control col-4 mb-3"
                style={{ maxWidth: 300 }}
              >
                <option value="">Seleccionar riesgo</option>
                <option value="Bajo">Bajo</option>
                <option value="Medio">Medio</option>
                <option value="Alto">Alto</option>
              </select>
            </div>
           
            <button
              className="btn mt-2"
             
              style={{ backgroundColor: "var(--naranja)", color: "white" }}
              onClick={Confirmar}
            >
              Confirmar
            </button>
          </div>
            )
          }
        </div>
      </Modal>
    </div>
  );
}
function convertirHoraArgentina(utcDate) {
    return new Date(utcDate).toLocaleString("es-AR", {
      timeZone: "America/Argentina/Buenos_Aires",
      hour12: false
    });
  }
export default Alert;

